<template>
  <div>
    <v-divider class="my-3" />
    <v-row class="px-4">
      <v-col
        cols="12"
        class="d-flex"
      >
        <h4 class="d-inline-flex text-overline">
          Option {{ index + 1 }}
        </h4>
        <v-btn
          title="Delete this rule"
          color="red darken-3"
          dark
          fab
          x-small
          class="d-inline-flex ms-auto"
          @click="onDelete(index)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          title="Insert new rule above"
          color="secondary"
          fab
          x-small
          class="d-inline-flex ms-2"
          @click="onInsertBefore(index)"
        >
          <v-icon>mdi-table-row-plus-before</v-icon>
        </v-btn>
        <v-btn
          title="Insert new rule below"
          color="secondary"
          fab
          x-small
          class="d-inline-flex ms-2"
          @click="onInsertAfter(index)"
        >
          <v-icon>mdi-table-row-plus-after</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        xs="6"
        sm="4"
        md="3"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required|integer"
        >
          <v-text-field
            label="Max MTOW (kg)"
            v-model.number="model.max"
            type="number"
            required
            :error-messages="errors"
            outlined
            dense
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        xs="6"
        sm="4"
        md="3"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            prepend-inner-icon="mdi-currency-gbp"
            label="Landing Fee (£)"
            v-model.number="model.fee"
            type="number"
            step="0.01"
            required
            :error-messages="errors"
            outlined
            dense
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { integer, required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, setInteractionMode } from "vee-validate";

export default {
  name: "MtowRule",
  props: {
    index: { type: Number, required: true },
    model: { type: Object, required: true },
    onInsertBefore: { type: Function, required: true },
    onInsertAfter: { type: Function, required: true },
    onDelete: { type: Function, required: true }
  },

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });
    extend("integer", {
      ...integer,
      message: "Must be a number"
    });
  },

  components: {
    ValidationProvider
  }
};
</script>
