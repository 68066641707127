<template>
  <v-row>
    <v-col cols="12">
      <validation-observer ref="observer">
        <v-form
          ref="form"
          :disabled="isSubmitting"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-card>
            <v-card-title>Landing Fee Config</v-card-title>
            <v-card-text class="py-4">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <v-select
                  label="Fee Mode"
                  hint="Select a mode for charging landing fees"
                  persistent-hint
                  :items="modes"
                  v-model="mode"
                  required
                  :error-messages="errors"
                  outlined
                />
              </validation-provider>
            </v-card-text>
            <v-card-text
              v-if="mode === 'flat-fee'"
              class="py-4"
            >
              <v-row>
                <v-col
                  cols="12"
                  xs="6"
                  class="py-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-text-field
                      prepend-inner-icon="mdi-currency-gbp"
                      label="Flat Landing Fee (£)"
                      hint="Required - choose the flat fee for every landing, or set to 0 for free landings."
                      persistent-hint
                      v-model.number="flatFee.fee"
                      type="number"
                      step="0.01"
                      required
                      :error-messages="errors"
                      outlined
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text
              v-if="mode === 'selectable-rules'"
              class="py-4"
            >
              <selectable-rule
                v-for="(rule, i) in selectableRules"
                :key="i"
                :index="i"
                :model="rule"
                :on-delete="onDelete"
                :on-insert-before="onInsertBefore"
                :on-insert-after="onInsertAfter"
              />
            </v-card-text>
            <v-card-text
              v-if="mode === 'mtow-rules'"
              class="py-4"
            >
              <mtow-rule
                v-for="(rule, i) in mtowRules"
                :key="i"
                :index="i"
                :model="rule"
                :on-delete="onDelete"
                :on-insert-before="onInsertBefore"
                :on-insert-after="onInsertAfter"
              />
            </v-card-text>
            <v-card-actions class="justify-center mb-2">
              <v-btn
                color="primary"
                rounded
                type="submit"
                :loading="isSubmitting"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import MtowRule from "@/components/landingFees/MtowRule";
import SelectableRule from "@/components/landingFees/SelectableRule";

export default {
  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });

    this.initConfig();
  },

  components: {
    MtowRule,
    SelectableRule,
    ValidationObserver,
    ValidationProvider
  },

  data() {
    return {
      isSubmitting: false,
      modes: [
        { value: "flat-fee", text: "Flat fee for every landing" },
        { value: "selectable-rules", text: "Pilot selects fee that applies" },
        { value: "mtow-rules", text: "Using MTOW for the aircraft" }
      ],
      mode: null /* "selectable-rules" */,
      flatFee: {
        fee: null /* 10 */
      },
      selectableRules: [
        this.newSelectableRule()
        /* { title: "Fixed wing & Autogyros", description: null, fee: 10 },
        { title: "Helicopter", description: null, fee: 120 },
        { title: "Circuits (by permission only)", description: null, fee: 25 },
        { title: "Based Aircraft", description: null, fee: 0 },
        { title: "Aeroplanes older than 1970", description: null, fee: 0 } */
      ],
      mtowRules: [
        this.newMtowRule()
        /* { max: 750, fee: 10.0 },
        { max: 1500, fee: 15.0 },
        { max: 2750, fee: 20.0 } */
      ]
    };
  },

  computed: {
    ...mapGetters({
      config: "auth/landingFeesConfig"
    })
  },

  methods: {
    initConfig() {
      if (this.config.mode === "flat-fee") {
        this.flatFee = this.config.flatFee;
      }
      if (this.config.mode === "selectable-rules") {
        this.selectableRules = this.config.selectableRules;
      }
      if (this.config.mode === "mtow-rules") {
        this.mtowRules = this.config.mtowRules;
      }

      this.mode = this.config.mode;
    },
    newSelectableRule() {
      return { title: null, description: null, fee: null };
    },
    newMtowRule() {
      return { max: null, fee: null };
    },

    onDelete(index) {
      if (this.mode === "selectable-rules") {
        if (this.selectableRules.length <= 1) {
          this.selectableRules = [{ title: "", description: null, fee: null }];
        } else {
          this.selectableRules.splice(index, 1);
        }
      } else if (this.mode === "mtow-rules") {
        if (this.mtowRules.length <= 1) {
          this.mtowRules = [{ max: null, fee: null }];
        } else {
          this.mtowRules.splice(index, 1);
        }
      }
    },
    onInsertBefore(index) {
      if (this.mode === "selectable-rules") {
        const newRule = { title: "", description: null, fee: null };
        this.selectableRules.splice(index, 0, newRule);
      } else if (this.mode === "mtow-rules") {
        const newRule = { max: null, fee: null };
        this.mtowRules.splice(index, 0, newRule);
      }
    },
    onInsertAfter(index) {
      if (this.mode === "selectable-rules") {
        const newRule = { title: "", description: null, fee: null };
        this.selectableRules.splice(index + 1, 0, newRule);
      } else if (this.mode === "mtow-rules") {
        const newRule = { max: null, fee: null };
        this.mtowRules.splice(index + 1, 0, newRule);
      }
    },

    onSubmit() {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            const data = { mode: this.mode };
            if (this.mode === "flat-fee") {
              data["flatFee"] = this.flatFee;
            }
            if (this.mode === "selectable-rules") {
              data["selectableRules"] = this.selectableRules;
            }
            if (this.mode === "mtow-rules") {
              data["mtowRules"] = this.mtowRules;
            }

            await this.$store.dispatch("auth/saveLandingFeesConfig", data);

            this.$router.push("/admin/airfield/fees-and-charges");
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
