<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            color="primary"
            dark
            @click.stop="dialog = true"
          >
            New Event
          </v-btn>
          <v-btn
            outlined
            class="mr-4"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <div class="flex-grow-1" />
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>

      <v-dialog
        v-model="dialog"
        max-width="500"
      >
        <!-- <v-card>
          <v-container>
            <v-form @submit.prevent="addEvent">
              // // <v-text-field v-model="name" type="text" label="event name (required)"></v-text-field> // //
                 <v-select
                :items="event_types"
                v-model="event_name"
                label="Event Name"
                outlined
              />
              <v-text-field
                v-model="public_message"
                type="text"
                label="Description / Memo / Notes"
                outlined
              />
              <v-text-field
                v-model="start_datetime"
                type="date"
                label="start (required)"
                outlined
              />
              <v-text-field
                v-model="end_datetime"
                type="date"
                label="end (required)"
                outlined
              /> 
              // // <v-text-field v-model="color" type="color" label="color (click to open color menu)"></v-text-field> // //
              <v-btn
                type="submit"
                color="primary"
                class="mr-4"
                @click.stop="dialog = false"
              >
                create event
              </v-btn>
            </v-form>
          </v-container>
        </v-card> -->



        <v-card>
          <v-container>
            <v-form @submit.prevent="addEvent">
              <v-select
                :items="event_types"
                v-model="event_name"
                label="Event Name"
                outlined
              />
              <v-text-field
                v-model="public_message"
                type="text"
                label="Description / Memo / Notes"
                outlined
              />
              <v-dialog
                ref="dateDialog"
                v-model="datePickerModal"
                :return-value.sync="start_date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    label="Event Start Date"
                    prepend-inner-icon="mdi-calendar"
                    required
                    v-model="formattedStartDate"
                    autocomplete="off"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="start_date"
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="grey"
                    @click="datePickerModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dateDialog.save(start_date);
                      datePickerModal = false;
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="starttime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                outlined
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="starttime"
                    label="Event Start Time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  />
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="starttime"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu.save(starttime)"
                />
              </v-menu>

              <v-dialog
                ref="dateDialog2"
                v-model="datePickerModal2"
                :return-value.sync="end_date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    label="Event End Date"
                    prepend-inner-icon="mdi-calendar"
                    required
                    v-model="formattedEndDate"
                    autocomplete="off"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="end_date"
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="grey"
                    @click="datePickerModal2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dateDialog2.save(end_date);
                      datePickerModal2 = false;
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="endtime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                outlined
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endtime"
                    label="Event End Time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  />
                </template>
                <v-time-picker
                  v-if="menu3"
                  v-model="endtime"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu3.save(endtime)"
                />
              </v-menu>

              <v-btn
                type="submit"
                color="primary"
                class="mr-4"
                @click.stop="dialogDate = false"
              >
                create event
              </v-btn>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogDate"
        max-width="500"
      >
        <v-card>
          <v-container>
            <v-form @submit.prevent="addEvent">
              <v-select
                :items="event_types"
                v-model="event_name"
                label="Event Name"
                outlined
              />
              <v-text-field
                v-model="public_message"
                type="text"
                label="Description / Memo / Notes"
                outlined
              />
              <v-dialog
                ref="dateDialog"
                v-model="datePickerModal"
                :return-value.sync="start_date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    label="Event Start Date"
                    prepend-inner-icon="mdi-calendar"
                    required
                    v-model="formattedStartDate"
                    autocomplete="off"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="start_date"
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="grey"
                    @click="datePickerModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dateDialog.save(start_date);
                      datePickerModal = false;
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="starttime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                outlined
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="starttime"
                    label="Event Start Time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  />
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="starttime"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu.save(starttime)"
                />
              </v-menu>

              <v-dialog
                ref="dateDialog2"
                v-model="datePickerModal2"
                :return-value.sync="end_date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    label="Event End Date"
                    prepend-inner-icon="mdi-calendar"
                    required
                    v-model="formattedEndDate"
                    autocomplete="off"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="end_date"
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="grey"
                    @click="datePickerModal2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dateDialog2.save(end_date);
                      datePickerModal2 = false;
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="endtime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                outlined
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endtime"
                    label="Event End Time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  />
                </template>
                <v-time-picker
                  v-if="menu3"
                  v-model="endtime"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu3.save(endtime)"
                />
              </v-menu>

              <v-btn
                type="submit"
                color="primary"
                class="mr-4"
                @click.stop="dialogDate = false"
              >
                create event
              </v-btn>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>

      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColour"
          :event-margin-bottom="3"
          :now="today"
          :type="type"
          :weekdays="weekdays"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="setDialogDate"
          @change="updateRange"
        />
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          full-width
          offset-x
        >
          <v-card
            :width="350"
            flat
          >
            <v-toolbar
              :color="selectedEvent.event_colour"
              dark
            >
              <v-btn
                @click="deleteEvent(selectedEvent.id)"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.event_name" />
              <div class="flex-grow-1" />
            </v-toolbar>

            <v-card-text>
              <form v-if="currentlyEditing !== selectedEvent.id">
                {{ selectedEvent.public_message }}
              </form>
              <form v-else>
                <!--  <v-select
                  :items="event_types"
                  v-model="selectedEvent.event_name"
                  label="Event Name"
                  outlined
                />
                <v-textarea auto-grow
                  v-model="selectedEvent.public_message"
                  type="text"
                  style="width: 100%"
                  :min-height="100"
                  placeholder="Add a public message"
                  outlined
                />
                <v-text-field
                  type="date"
                  v-model="selectedEvent.start_datetime"
                  label="start (required)"
                  outlined
                />
                <v-text-field
                  v-model="selectedEvent.end_datetime"
                  type="date"
                  label="end (required)"
                  outlined
                /> -->
                <v-select
                  :items="event_types"
                  v-model="selectedEvent.event_name"
                  label="Event Name"
                  outlined
                />
                <v-text-field
                  v-model="selectedEvent.public_message"
                  type="text"
                  label="Description / Memo / Notes"
                  outlined
                />
                <v-dialog
                  ref="dateDialog"
                  v-model="datePickerModal"
                  :return-value.sync="selectedEvent.start_datetime"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      label="Event Start Date"
                      prepend-inner-icon="mdi-calendar"
                      required
                      v-model="formattedStartDateEdit"
                      autocomplete="off"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="start_date_edit"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="grey"
                      @click="datePickerModal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dateDialog.save(start_date_edit);
                        datePickerModal = false;
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="selectedEvent.starttime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  outlined
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedEvent.starttime"
                      label="Event Start Time"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    />
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="selectedEvent.starttime"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu.save(selectedEvent.starttime)"
                  />
                </v-menu>

                <v-dialog
                  ref="dateDialog2"
                  v-model="datePickerModal2"
                  :return-value.sync="selectedEvent.end_datetime"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      label="Event End Date"
                      prepend-inner-icon="mdi-calendar"
                      required
                      v-model="formattedEndDateEdit"
                      autocomplete="off"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="end_date_edit"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="grey"
                      @click="datePickerModal2 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dateDialog2.save(end_date_edit);
                        datePickerModal2 = false;
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="selectedEvent.endtime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  outlined
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedEvent.endtime"
                      label="Event End Time"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    />
                  </template>
                  <v-time-picker
                    v-if="menu3"
                    v-model="selectedEvent.endtime"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu3.save(selectedEvent.endtime)"
                  />
                </v-menu>





                <!-- <v-text-field v-model="color" type="color" label="color (click to open color menu)"></v-text-field> -->
                <!--  <v-btn
                  type="submit"
                  color="primary"
                  class="mr-4"
                  @click.stop="dialog = false"
                >
                  create event
                </v-btn> -->
              </form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                close
              </v-btn>
              <v-btn
                v-if="currentlyEditing !== selectedEvent.id"
                text
                @click.prevent="editEvent(selectedEvent)"
              >
                edit
              </v-btn>
              <v-btn
                text
                v-else
                type="submit"
                @click.prevent="updateEvent(selectedEvent)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
// import firestore from "@/firebase";
// const airfieldsCollection = "airfields";
// const calendarCollection = "calendar";
// const db = firebase.firestore();
// import { functions } from "../../../firebase";



//import { mapGetters } from 'vuex'

// const getCalendarEvents = async data => {
//   try {
//     //  await firebase.auth().sendSignInLinkToEmail(data.email, {
//     //    url: window.location.origin + "/magicLinkLogin",
//     //    handleCodeInApp: true
//     //  });
//     const events = functions.httpsCallable("calendar-getCalendarEvents");
//     //formData.dateTime = `${formData.date}T${formData.time}:00`;
//     console.log("get_calendar data : ", data);
//     await events({
//       airfieldId: "1234"
//     });

//   } catch (error) {
//     console.error(error);
//   }
// };

import { DateTime } from "luxon";
import { mapGetters } from "vuex";
// import {
//   ValidationProvider,
//  // ValidationObserver
// } from "vee-validate";
// // import ArrivalsList from "../../../components/ArrivalsList.vue";
// import DatesHelper from "../../../store/dates-helper";

export default {
  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    weekdays: [1,2,3,4,5,6,0],
    datePickerModal: false,
    event_name: null,
    event_type: null,
    public_message: null,
    private_message: null,
    start_datetime: null,
    start_date: null,
    start_time: null,
    start_date_edit: null,
    end_date_edit: null,
    end_date: null,
    end_datetime: null,
    end_time: null,
    event_colour: '#1976D2', // default event color
    currentlyEditing: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    timePickerModal: false,
    timePickerModal2: false,
    datePickerModal2: false,
    //events: [],
    dialog: false,
    dialogDate: false,
    event_types: [
      "closed",
      "auto-ppr",
      "manual-ppr",
      "limited"
    ],
    starttime: null,
    endtime: null,
    menu2: false,
    modal2: false,
    menu3: false
  }),
  mounted() {
    this.$store.dispatch("calendar/subscribe");
    console.log("NOW CHECK : ", this.events);
  },
  beforeDestroy() {
    this.$store.dispatch("calendar/unsubscribe");
  },
  // components: {
  //   ValidationProvider,
  //  // ValidationObserver    
  // },
  computed: {
    ...mapGetters({ events: "calendar/items" }),
    formattedStartDate() {
      return this.start_date
        ? DateTime.fromISO(this.start_date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
        : "";
    },
    formattedStartDateEdit(){
      console.log("CHECK HERE : ", this.selectedEvent.start_datetime);
       return this.start_date_edit 
        ? DateTime.fromISO(this.start_date_edit).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
        : DateTime.fromISO(this.selectedEvent.start_datetime).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    },
    formattedEndDate() {
      return this.end_date
        ? DateTime.fromISO(this.end_date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
        : "";
    },
    formattedEndDateEdit(){
      console.log("CHECK HERE : ", this.selectedEvent.end_datetime);
       return this.end_date_edit 
        ? DateTime.fromISO(this.end_date_edit).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
        : DateTime.fromISO(this.selectedEvent.end_datetime).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    },
    title () {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }
      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth
      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear
      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)
      switch (this.type) {
        case 'month':
        return `${startMonth} ${startYear}`
        case 'week':
        case '4day':
        return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
        return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'long',
      })
    }
  },
  methods: {
    async getEvents () {
      // console.log("get events?");
      this.$store.dispatch("calendar/fetch");
      // let snapshot = await getCalendarEvents() //firestore.collection(`${airfieldsCollection}`+"/1234/"+`${calendarCollection}`).get()
      // const events = []
      // snapshot.forEach(doc => {
      //   let appData = doc.data()
      //   appData.id = doc.id
      //   events.push(appData)
      // })
      // this.events = events
    },
    setDialogDate( { date }) {
      this.dialogDate = true
      this.focus = date
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColour (event) {
      return event.color
    },
    setToday () {
      this.focus = this.today
    },
    prev () {
      this.$refs.calendar.prev();
    },
    next () {
      this.$refs.calendar.next();
    },
    async addEvent () {
      if(this.public_message == null){
        this.public_message = " ";
      }
      if(this.event_type == null){
        this.event_type = " ";
      
      }
        // console.log("ADD EVENT HERE :::");
        // console.log(this.starttime);
        // console.log(this.endtime);
        // console.log(this.start_date);
        // console.log(this.end_date);
        var sd = DateTime.fromISO(this.start_date);
        var sdt = null;

        if(this.start_date && this.starttime){
          var st = this.starttime.split(":");
          sdt = sd.set({
              hour: parseInt(st[0]),
              minute: parseInt(st[1]),
          });
          console.log("added? ", sdt);
        } else if(this.start_date && this.starttime == ""){
          sdt = sd.set({
              hour: 0,
              minute: 0,
          });
          //console.log("added? ", sdt);
        } else {
          alert("Please set a start date");
          return false;
        }

        var ed = DateTime.fromISO(this.end_date);
        var edt = null;
        if(this.end_date && this.endtime){
          var et = this.endtime.split(":");
          edt = ed.set({
              hour: parseInt(et[0]),
              minute: parseInt(et[1]),
          });
          console.log("added? ", edt);
        } else if(this.end_date && this.endtime == ""){
          edt = ed.set({
              hour: 0,
              minute: 0,
          });
          console.log("added? ", edt);
          //console.log("added? ", sdt);
        } else {
          alert("Please set an end date");
          return false;
        }


      if (this.event_name && this.event_type && this.public_message && sdt !== null && edt !== null) {
        try {
          await this.$store.dispatch("calendar/new", {event_name: this.event_name, event_type: this.event_type, public_message: this.public_message, start_datetime: sdt, end_datetime: edt, event_colour: this.event_colour, private_message: 'nothing'});
        } catch (e) {
          console.error(e);
        }
        this.getEvents()
        this.event_name = '',
        this.public_message = '',
        this.private_message = 'not yet in use',
        this.start_datetime = '',
        this.end_datetime = '',
        this.end_date = '',
        this.start_date = '',
        this.starttime = '',
        this.endtime = '',
        this.event_colour = ''
      } else {
        alert('You must enter event name, start, and end time')
      }
    },
    editEvent (ev) {
      this.currentlyEditing = ev.id
    },
    async updateEvent (ev) {
      // await firestore.collection(`${airfieldsCollection}`+"/1234/"+`${calendarCollection}`).doc(this.currentlyEditing).update({
      //   details: ev.details
      // })

      var sd = DateTime.fromISO(ev.start_datetime);
      var sdt = null;

      if(ev.start_datetime && ev.starttime){
        var st = ev.starttime.split(":");
        sdt = sd.set({
            hour: parseInt(st[0]),
            minute: parseInt(st[1]),
        });
        console.log("added? ", sdt);
      } else if(ev.start_datetime && ev.starttime == ""){
        sdt = sd.set({
            hour: parseInt(0),
            minute: parseInt(0),
        });
        //console.log("added? ", sdt);
      } else {
        alert("Please set a start date");
        return false;
      }

      var ed = DateTime.fromISO(ev.end_datetime);
      var edt = null;
      if(ev.end_datetime && ev.endtime){
        var et = ev.endtime.split(":");
        edt = ed.set({
            hour: parseInt(et[0]),
            minute: parseInt(et[1]),
        });
        console.log("added? ", edt);
      } else if(ev.end_datetime && ev.endtime == ""){
        edt = ed.set({
            hour: parseInt(0),
            minute: parseInt(0),
        });
        console.log("added? ", edt);
        //console.log("added? ", sdt);
      } else {
        alert("Please set an end date");
        return false;
      }

      const cleaner_event = {
        id: ev.id,
        event_name: ev.event_name,
        start_datetime: sdt,
        end_datetime: edt,
        public_message: ev.public_message,
        private_message: ev.private_message,
        event_colour: ev.color,
        event_type: ev.event_type
      }
      await this.$store.dispatch("calendar/updateEvent", cleaner_event);
      this.selectedOpen = false,
      this.currentlyEditing = null
    },
    async deleteEvent (ev) {
      // await firestore.collection(`${airfieldsCollection}`+"/1234/"+`${calendarCollection}`).doc(ev).delete()
        

      await this.$store.dispatch("calendar/deleteEvent", {id: ev});
      // console.log("delete: ", ev);
      this.selectedOpen = false,
      this.getEvents()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        // console.log("START : ", event);
        event.start_date = DateTime.fromFormat(event.start, "yyyy-MM-dd HH:mm:ss").toFormat("yyyy-MM-dd");
        event.end_date = DateTime.fromFormat(event.end, "yyyy-MM-dd HH:mm:ss").toFormat("yyyy-MM-dd");

        event.start_datetime = DateTime.fromFormat(event.start, "yyyy-MM-dd HH:mm:ss").toFormat("yyyy-MM-dd");
        this.start_date_edit = DateTime.fromFormat(event.start, "yyyy-MM-dd HH:mm:ss").toFormat("yyyy-MM-dd");
        event.end_datetime = DateTime.fromFormat(event.end, "yyyy-MM-dd HH:mm:ss").toFormat("yyyy-MM-dd");
        this.end_date_edit = DateTime.fromFormat(event.end, "yyyy-MM-dd HH:mm:ss").toFormat("yyyy-MM-dd");

        event.starttime = DateTime.fromFormat(event.start, "yyyy-MM-dd HH:mm:ss").toFormat("HH:mm");
        event.endtime = DateTime.fromFormat(event.end, "yyyy-MM-dd HH:mm:ss").toFormat("HH:mm");
        
        event.event_name = event.name;
        // console.log("START : ", event.start_datetime);
        // console.log("END : ", event.end_datetime);
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.selectedOpen = true, 10)
      }
      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    },
    async updateRange ({ start_datetime, end_datetime }) {
      // await this.$store.dispatch("calendar/changeBase", {view: this.type, base_date: this.focus});
      await this.$store.dispatch("calendar/changeBase", {view: this.type, base_date: this.focus});
      
      // console.log("update range?");
      this.start_datetime = start_datetime;
      this.end_datetime = end_datetime;
    },
    nth (d) {
      return d > 3 && d < 21
      ? 'th'
      : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    }
  }
}
</script>