<template>
  <div>
    <div v-if="config.mode == 'flat-fee'">
      <h4
        v-if="showModeTitle"
        class="text-subtitle-1"
      >
        Flat fee for every landing
      </h4>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>All landings</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="font-weight-regular">
            <span v-if="config.flatFee.fee == 0.0">Free</span>
            <i18n-n
              v-else
              :value="config.flatFee.fee"
              format="currency"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="config.mode == 'selectable-rules'">
      <h4
        v-if="showModeTitle"
        class="text-subtitle-1"
      >
        Pilot selects fee that applies
      </h4>
      <v-list>
        <v-list-item
          v-for="(rule, i) in config.selectableRules"
          :key="i"
          :three-line="!!rule.description"
        >
          <v-list-item-content>
            <v-list-item-title>{{ rule.title }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ rule.description || "" }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="font-weight-regular">
            <span v-if="rule.fee == 0.0">Free</span>
            <i18n-n
              v-else
              :value="rule.fee"
              format="currency"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="config.mode == 'mtow-rules'">
      <h4
        v-if="showModeTitle"
        class="text-subtitle-1"
      >
        Using MTOW for the aircraft
      </h4>
      <v-list>
        <v-list-item
          v-for="(rule, i) in config.mtowRules"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="i == 0">
                0 kg &nbsp;<v-icon x-small>mdi-arrow-right</v-icon>&nbsp;
              </span>
              <span v-else-if="config.mtowRules[i - 1]">
                <i18n-n :value="config.mtowRules[i - 1].max + 1" /> kg
                &nbsp;<v-icon x-small>mdi-arrow-right</v-icon>&nbsp;
              </span>
              <i18n-n :value="rule.max" /> kg
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="font-weight-regular">
            <span v-if="rule.fee == 0.0">Free</span>
            <i18n-n
              v-else
              :value="rule.fee"
              format="currency"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "RenderLandingFees",
  props: {
    config: { type: Object, required: true },
    showModeTitle: { type: Boolean, default: false }
  }
};
</script>
