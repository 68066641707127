<template>
  <div>
    <v-row
      justify="center"
      class="instructions"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
      >
        <v-card>
          <v-card-title>Printable Sign</v-card-title>
          <v-card-text class="text-body-1">
            <p>
              Maybe you need to collect landing fees out-of-hours, or you're an
              unmanned farm strip. Print out this sign so pilots can scan the QR
              code to make quick and easy payment by card.
            </p>
            <p class="mb-0">
              Attach the sign to your notice board, at your check-in point, at
              the fuel pumps, or anywhere else pilots will find it.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              rounded
              color="accent"
              @click="printPage"
            >
              <v-icon left>
                mdi-printer
              </v-icon>
              Print
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-card
          light
          flat
          tile
          class="printable-card"
        >
          <v-card-text>
            <h3 class="text-overline text-center mb-0">
              Welcome to
            </h3>
            <h1 class="text-h3 text-center mt-0 mb-4">
              {{ airfieldName }}
            </h1>
            <v-divider class="my-5" />
            <v-row>
              <v-col
                cols="6"
                class="pe-6"
              >
                <h2 class="text-h4 mb-4">
                  Landing Fees
                </h2>
                <render-landing-fees
                  :config="landingConfig"
                  :show-mode-title="false"
                  class="landing-fee-list"
                />

                <div
                  v-if="parkingConfig && parkingConfig.mode"
                  class="mt-4"
                >
                  <h2 class="text-h4 mb-4">
                    Parking Fees
                  </h2>
                  <render-parking-fees
                    :config="parkingConfig"
                    :show-mode-title="false"
                    class="parking-fee-list"
                  />
                </div>
              </v-col>

              <v-col
                cols="6"
                class="ps-6"
              >
                <h2 class="text-h4 mb-4">
                  How to pay?
                </h2>
                <p class="text-body-1">
                  Scan the code with your mobile device to visit our payment
                  page.
                </p>
                <div class="text-center">
                  <qr-code
                    :airfield-id="airfieldId"
                    :size="240"
                    class=""
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RenderLandingFees from "@/components/landingFees/RenderLandingFees.vue";
import RenderParkingFees from "@/components/landingFees/RenderParkingFees.vue";
import QrCode from "@/components/landingFees/QrCode.vue";

export default {
  name: "PrintableSign",

  components: {
    RenderLandingFees,
    RenderParkingFees,
    QrCode
  },

  computed: {
    ...mapGetters({
      airfieldId: "auth/airfieldId",
      airfieldName: "auth/airfieldName",
      landingConfig: "auth/landingFeesConfig",
      parkingConfig: "auth/parkingFeesConfig",
    })
  },

  methods: {
    async printPage() {
      this.$nextTick(() => {
        window.print();
      });
    }
  }
};
</script>

<style lang="scss">
.landing-fee-list, 
.parking-fee-list {
  .v-list-item {
    padding: 0;
  }
}

.printable-card {
  svg {
    max-width: 100%;
    height: auto !important;
  }
}

@page {
  size: A4 portrait;
  margin: 0;
}
@media print {
  .v-app-bar,
  .v-navigation-drawer,
  .v-footer,
  .instructions {
    display: none !important;
  }
  .v-main {
    padding: 0 !important;
  }

  /* .printable-card {
    width: 210mm;
    height: 297mm;
  } */
}
</style>
