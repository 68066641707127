<template>
  <div class="d-inline-flex white">
    <a
      :href="qrCode.url"
      target="_blank"
      rel="noopener"
      class="d-inline-flex"
    >
      <qrcode-vue
        level="Q"
        :size="qrCode.size"
        :value="qrCode.url"
        render-as="svg"
        class="d-inline-flex qr-code"
      />
    </a>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { RouteGenerator } from "@/router/route-generator";

export default {
  name: "QrCode",
  props: {
    airfieldId: { type: String, required: true },
    size: { type: Number, default: 160 }
  },

  components: {
    QrcodeVue
  },

  computed: {
    qrCode() {
      return {
        url: RouteGenerator.paymentLink(this.airfieldId),
        size: this.size
      };
    }
  }
};
</script>
