<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon class="pe-3">
              mdi-cash-multiple
            </v-icon>
            Fees &amp; Charges
          </v-card-title>
          <v-card-text
            v-if="isStripeOnboardingComplete && !isStripeChargesEnabled"
            class="text-body-1 text-center warning--text"
          >
            <v-icon left>
              mdi-warning
            </v-icon>
            Payments cannot currently be taken! It looks like Stripe needs more
            information before enabling payment charges on your account, please
            visit your
            <v-btn
              rounded
              color="primary"
              href="https://dashboard.stripe.com/"
              target="_blank"
              rel="noopener"
              class="ma-2"
            >
              Stripe Dashboard
            </v-btn>
          </v-card-text>
          <v-card-text
            v-else-if="isStripeOnboardingComplete"
            class="text-body-1 text-center"
          >
            To view customer payments, visit the Payments page in your
            <v-btn
              rounded
              color="primary"
              href="https://dashboard.stripe.com/payments"
              target="_blank"
              rel="noopener"
              class="ma-2"
            >
              Stripe Dashboard
            </v-btn>
          </v-card-text>
          <v-card-text
            v-else-if="!isStripeOnboardingComplete"
            class="text-body-1 text-center"
          >
            Looks like you haven't completed setting up your Stripe account,
            continue to
            <v-btn
              rounded
              color="primary"
              to="/admin/airfield/setup-payments"
              class="ma-2"
            >
              Setup Payments
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card class="fill-height">
          <v-card-title>
            Landing Fees
            <v-spacer />
            <v-btn
              color="primary"
              fab
              x-small
              elevation="0"
              to="/admin/airfield/fees-and-charges/landing-fees/edit"
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-body-1">
            <render-landing-fees
              v-if="Object.keys(landingConfig).length > 0"
              :config="landingConfig"
              :show-mode-title="true"
            />
            <div
              v-else
              class="text-center py-4"
            >
              <v-btn
                color="primary"
                rounded
                to="/admin/airfield/fees-and-charges/landing-fees/edit"
              >
                Setup Landing Fees
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card class="fill-height">
          <v-card-title>
            Parking Fees
            <v-spacer />
            <v-btn
              color="primary"
              fab
              x-small
              elevation="0"
              to="/admin/airfield/fees-and-charges/parking-fees/edit"
            >
              <v-icon>mdi-pen</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-body-1">
            <render-parking-fees
              v-if="Object.keys(parkingConfig).length > 0"
              :config="parkingConfig"
              :show-mode-title="true"
            />
            <div
              v-else
              class="text-center py-4"
            >
              <v-btn
                color="primary"
                rounded
                to="/admin/airfield/fees-and-charges/parking-fees/edit"
              >
                Setup Parking Fees
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title>
            Collecting fees
          </v-card-title>
          <v-card-text class="text-body-1">
            <v-list>
              <v-list-item>
                <v-list-item-icon class="me-6">
                  <v-icon>mdi-email-check-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Email link</v-list-item-title>
                  <v-list-item-subtitle>
                    Every PPR email automatically includes a link to pay fees.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/admin/airfield/fees-and-charges/printable-sign">
                <v-list-item-icon class="me-6">
                  <v-icon>mdi-qrcode</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>QR Code</v-list-item-title>
                  <v-list-item-subtitle>
                    Click here to print out a sign for your notice board, and
                    pilots can scan the QR code to pay.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="me-6">
                  <v-icon>mdi-link-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Manual Link</v-list-item-title>
                  <v-list-item-subtitle>
                    Share this link with anyone who needs to pay<br>
                    <a
                      :href="paymentLink"
                      target="_blank"
                      rel="noopener"
                    >{{
                      paymentLink
                    }}</a>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <v-btn
                      color="primary"
                      text
                      v-clipboard:copy="paymentLink"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                    >
                      <v-icon left>
                        mdi-content-copy
                      </v-icon>
                      Copy
                    </v-btn>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import {mapGetters} from "vuex";
import RenderLandingFees from "@/components/landingFees/RenderLandingFees.vue";
import RenderParkingFees from "@/components/landingFees/RenderParkingFees.vue";
import {RouteGenerator} from "@/router/route-generator";

Vue.use(VueClipboard);

const copiedSuccess = "Copied payment link to your clipboard.";
const copiedError =
  "There was a problem copying the payment link, please right-click and copy link, or select and copy manually.";

export default {
  components: {
    RenderLandingFees,
    RenderParkingFees,
  },

  data: () => ({
    snackbar: false,
    snackbarText: copiedSuccess
  }),

  computed: {
    ...mapGetters({
      airfieldId: "auth/airfieldId",
      hasStripeAccount: "auth/airfieldHasStripeAccount",
      isStripeOnboardingComplete: "auth/airfieldStripeOnboardingComplete",
      isStripeChargesEnabled: "auth/airfieldStripeChargesEnabled",
      landingConfig: "auth/landingFeesConfig",
      parkingConfig: "auth/parkingFeesConfig",
    }),
    paymentLink() {
      return RouteGenerator.paymentLink(this.airfieldId);
    }
  },

  methods: {
    onCopy() {
      this.snackbarText = copiedSuccess;
      this.snackbar = true;
    },
    onError(e) {
      console.log(e);
      this.snackbarText = copiedError;
      this.snackbar = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list-item__subtitle {
  margin-top: 4px;
  overflow: visible !important;
  white-space: normal !important;

  a {
    line-break: anywhere;
  }
}
</style>
